// Standardized breakpoints
$breakpoint-xs: 599px;
$breakpoint-sm: 767px;
$breakpoint-md: 1023px;
$breakpoint-lg: 1439px;
$breakpoint-xl: 1919px;

// Min-width breakpoints
$breakpoint-xs-min: 600px;
$breakpoint-sm-min: 768px;
$breakpoint-md-min: 1024px;
$breakpoint-lg-min: 1440px;

.inventory-page {
  background: $background;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    margin-bottom: 20px;

    @media (max-width: $breakpoint-lg) {
      padding-right: 12px;
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  .packages-loading-notice {
    position: fixed;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: $secondary;
    padding: 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: max-content;
    @media screen and (max-width: $breakpoint-sm) {
      bottom: 84px;
    }
  }
}

.mobileBreak {
  @media (max-width: 600px) {
    margin-top: 15px !important;
  }
}

.inventory-items {
  margin: 0 auto;
  padding: 0 15px;

  @media (max-width: $breakpoint-sm) {
    padding: 0 10px;
  }

  .top-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .controls-container {
    @media (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
      row-gap: 8px;
    }
  }

  .filters-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    @media (max-width: $breakpoint-sm) {
      width: 100%;
      justify-content: space-between;
    }

    @media (max-width: $breakpoint-xs) {
      flex-direction: column;

      .q-select {
        width: 100%;
      }
    }
  }

  // Main inventory header styling
  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    position: relative;

    @media (min-width: 1324px) and (max-width: 1432px) {
      .inventory-items-card-main {
        flex: 0 0 16%;
      }

      .inventory-items-card-license {
        flex: 0 0 8%;
      }

      .inventory-items-card-category-type,
      .inventory-items-card-strain-type,
      .inventory-items-card-listings {
        flex: 0 0 7%;
      }

      .inventory-items-card-packages {
        flex: 0 0 10%;
      }

      .inventory-items-card-location {
        flex: 0 0 8%;
      }

      .inventory-items-card-toggle {
        flex: 0 0 11%;
      }
    }

    &-wrapper {
      padding: 4px 12px;
      background-color: #fff;
      margin-bottom: 10px;
      border-radius: 8px;
      overflow-x: hidden;

      @media (max-width: $breakpoint-lg) {
        padding: 4px 8px;
      }
    }

    .inventory-items-card {
      &-image-header {
        flex: 0 0 70px;

        @media (max-width: $breakpoint-lg) {
          flex: 0 0 60px;
        }

        @media (max-width: $breakpoint-md) {
          flex: 0 0 10%;
          padding-left: 50px;
        }
      }

      &-main {
        flex: 0 0 19%;

        @media (max-width: $breakpoint-lg) {
          flex: 0 0 19%;
        }

        @media (max-width: $breakpoint-md) {
          flex: 0 0 18%;
        }

        @media (max-width: 900px) {
          flex: 0 0 30%;
        }

        @media (max-width: $breakpoint-sm) {
          flex: 0 0 100%;
          padding-left: 0;
          margin-bottom: 10px;
        }

        &.for-retailer {
          padding-left: 0;
        }
      }

      &-license {
        flex: 0 0 8%;
        padding-left: 5px;

        @media (max-width: $breakpoint-lg) {
          flex: 0 0 8%;
        }

        @media (max-width: $breakpoint-md) {
          flex: 0 0 9%;
        }

        @media (max-width: 900px) {
          flex: 0 0 15%;
        }

        @media (max-width: $breakpoint-sm) {
          // flex: 0 0 50%;
        }
      }

      &-category-type,
      &-strain-type {
        flex: 0 0 8%;

        @media (max-width: $breakpoint-lg) {
          flex: 0 0 8%;
        }

        @media (max-width: $breakpoint-md) {
          flex: 0 0 8%;
        }

        @media (max-width: 900px) {
          flex: 0 0 15%;
        }

        @media (max-width: $breakpoint-sm) {
          flex: 0 0 50%;
          padding: 5px 0;
          display: block !important;
        }
      }

      &-listings {
        flex: 0 0 8%;
        padding-left: 5px;

        @media (max-width: $breakpoint-lg) {
          flex: 0 0 9%;
        }

        @media (max-width: $breakpoint-md) {
          flex: 0 0 8%;
        }

        @media (max-width: 900px) {
          flex: 0 0 15%;
        }

        @media (max-width: $breakpoint-sm) {
          flex: 0 0 50%;
          padding: 5px 0;
          display: block !important;
        }
      }

      // Packages/QOH column
      &-packages {
        flex: 0 0 11%;
        padding-left: 5px;

        @media (max-width: $breakpoint-lg) {
          flex: 0 0 11%;
        }

        @media (max-width: $breakpoint-md) {
          flex: 0 0 12%;
        }

        @media (max-width: 900px) {
          flex: 0 0 20%;
        }

        @media (max-width: $breakpoint-sm) {
          flex: 0 0 50%;
          padding: 5px 0;
          display: block !important;
        }
      }

      &-location {
        flex: 0 0 9%;
        padding-left: 5px;

        @media (max-width: $breakpoint-lg) {
          flex: 0 0 8%;
        }

        @media (max-width: $breakpoint-md) {
          flex: 0 0 10%;
        }

        @media (max-width: 900px) {
          flex: 0 0 15%;
        }

        @media (max-width: $breakpoint-sm) {
          flex: 0 0 50%;
          padding: 5px 0;
          display: block !important;
        }
      }

      &-toggle {
        flex: 0 0 12%;
        padding-left: 5px;

        @media (max-width: $breakpoint-md) {
          flex: 0 0 11%;
        }

        @media (max-width: 900px) {
          flex: 0 0 20%;
        }

        @media (max-width: $breakpoint-sm) {
          flex: 0 0 100%;
          margin-top: 10px;
          padding-left: 0;
          display: block !important;
        }
      }
    }
  }

  &-scroll {
    max-height: 100vh;
  }

  // Inventory item card styling
  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    position: relative;

    @media (min-width: 1500px) {
    }

    @media (max-width: $breakpoint-lg) {
      font-size: 13px;
    }

    @media (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
      padding-right: 0;
    }

    &.with-packages {
      cursor: pointer;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 10px 12px;
      margin-bottom: 10px;
      position: relative;

      .item-container {
        width: 100%;
      }

      @media (max-width: $breakpoint-lg) {
        padding: 8px 10px;
      }

      &:not(.q-skeleton) {
        background: #fff;
      }
    }

    &-main {
      flex: 0 0 25%;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-lg) {
        flex: 0 0 27%;
      }

      @media (max-width: $breakpoint-md) {
        flex: 0 0 30%;
      }

      @media (max-width: 900px) {
        flex: 0 0 45%;
      }

      @media (max-width: $breakpoint-sm) {
        flex: 0 0 100%;
        margin-bottom: 10px;
      }

      .inventory-items-card-image {
        background: $secondary;
        border-radius: 8px;
        margin-right: 16px;
        min-width: 60px;
        min-height: 60px;

        @media (max-width: $breakpoint-lg) {
          margin-right: 10px;
          min-width: 50px;
          min-height: 50px;
        }

        &.no-image {
          color: $primary;
          font-weight: 500;
          padding: 10px;
          width: 60px;
          height: 60px;
          text-align: center;
          position: relative;

          @media (max-width: $breakpoint-lg) {
            width: 50px;
            height: 50px;
            padding: 8px;
          }

          .no-image-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 13px;
            text-align: center;
            line-height: 1.2;
          }
        }
      }

      .inventory-items-card-title {
        font-size: 16px;
        font-weight: 600;
        margin-right: 2px;
        word-break: break-word;

        @media (max-width: $breakpoint-lg) {
          font-size: 14px;
        }
      }
    }

    &-license {
      flex: 0 0 8%;
      padding-left: 5px;

      @media (max-width: $breakpoint-lg) {
        flex: 0 0 8%;
      }

      @media (max-width: $breakpoint-md) {
        flex: 0 0 9%;
      }

      @media (max-width: 900px) {
        flex: 0 0 15%;
      }

      @media (max-width: $breakpoint-sm) {
        flex: 0 0 50%;
        padding: 5px 0;

        &::before {
          content: attr(data-label);
          font-weight: 500;
          color: #646464;
          display: block;
          font-size: 12px;
        }
      }
    }

    &-category-type,
    &-strain-type,
    &-listings {
      flex: 0 0 7.5%;
      padding-left: 5px;

      @media (max-width: $breakpoint-lg) {
        flex: 0 0 7.5%;
      }

      @media (max-width: $breakpoint-md) {
        flex: 0 0 8%;
      }

      @media (max-width: 900px) {
        flex: 0 0 15%;
      }

      @media (max-width: $breakpoint-sm) {
        flex: 0 0 50%;
        padding: 5px 0;

        &::before {
          content: attr(data-label);
          font-weight: 500;
          color: #646464;
          display: block;
          font-size: 12px;
        }
      }
    }

    &-packages {
      flex: 0 0 12%;

      @media (max-width: $breakpoint-lg) {
        flex: 0 0 12%;
      }

      @media (max-width: $breakpoint-md) {
        flex: 0 0 12%;
      }

      @media (max-width: 900px) {
        flex: 0 0 20%;
      }

      @media (max-width: $breakpoint-sm) {
        flex: 0 0 50%;
        padding: 5px 0;

        &::before {
          content: attr(data-label);
          font-weight: 500;
          color: #646464;
          display: block;
          font-size: 12px;
        }
      }
    }

    &-location {
      flex: 0 0 9%;
      padding-left: 5px;

      @media (max-width: $breakpoint-lg) {
        flex: 0 0 8%;
      }

      @media (max-width: $breakpoint-md) {
        flex: 0 0 10%;
      }

      @media (max-width: 900px) {
        flex: 0 0 15%;
      }

      @media (max-width: $breakpoint-sm) {
        flex: 0 0 50%;
        padding: 5px 0;

        &::before {
          content: attr(data-label);
          font-weight: 500;
          color: #646464;
          display: block;
          font-size: 12px;
        }
      }
    }

    &-toggle {
      flex: 0 0 12%;
      padding-left: 5px;

      @media (max-width: $breakpoint-md) {
        flex: 0 0 11%;
      }

      @media (max-width: 900px) {
        flex: 0 0 20%;
      }

      @media (max-width: $breakpoint-sm) {
        flex: 0 0 100%;
        margin-top: 10px;
        padding-left: 0;
      }
    }

    &-actions-buttons {
      position: absolute;
      top: 2px;
      right: 2px;
      display: flex;
      gap: 6px;
      z-index: 5;

      @media (max-width: $breakpoint-lg) {
        top: 0;
        right: 0;
        gap: 5px;
      }

      @media (max-width: $breakpoint-md) {
        right: 5px;
      }

      @media (max-width: 900px) {
        top: 10px;
        right: 10px;
      }

      @media (max-width: $breakpoint-sm) {
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        justify-content: flex-end;
        margin-top: 10px;
      }

      .q-btn {
        min-width: auto;
        padding: 4px 16px;

        @media (max-width: $breakpoint-lg) {
          padding: 4px 14px;
        }
      }
    }

    &-packages-list {
      margin-top: 20px;
      width: 100%;

      @media (max-width: $breakpoint-lg) {
        margin-top: 16px;
      }

      &-table {
        thead {
          tr {
            height: auto;
            @media (max-width: $breakpoint-sm) {
              display: none;
            }
          }
          th {
            border: none;
            color: #646464;
            font-size: 12px;
            padding-top: 0;
            padding-bottom: 0;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
        tbody {
          tr {
            @media (max-width: $breakpoint-xs) {
              display: flex;
              flex-wrap: wrap;
              border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }
          }
          td {
            color: #092629;
            padding: 15px;
            font-size: 14px;

            @media (max-width: $breakpoint-lg) {
              padding: 12px;
              font-size: 13px;
            }

            @media (max-width: $breakpoint-xs) {
              border: none;
              height: auto;
              padding: 15px;
            }
            &:first-child {
              user-select: text !important;
              padding-left: 0;
              @media (max-width: $breakpoint-xs) {
                width: 100%;
                font-weight: 500;
                margin-bottom: -15px;
              }
            }
            &:nth-child(2),
            &:nth-child(3) {
              @media (max-width: $breakpoint-xs) {
                padding-left: 0;
                padding-top: 20px;
              }
            }
            &:last-child {
              padding-right: 0;
              @media (max-width: $breakpoint-xs) {
                flex-grow: 1;
              }
            }
            &:before {
              @media (max-width: $breakpoint-xs) {
                content: attr(data-th) !important;
                display: inline-block;
                font-weight: 500;
                color: #646464;
                margin-right: 8px;
                min-width: 80px;
              }
            }
          }
        }
      }
    }

    &-clickable {
      color: $primary;
      font-weight: 500;

      .accordion-icon {
        color: #646464;
        background: #f7f7f7;
        width: 16px;
        height: 16px;
        border-radius: 50%;

        @media (max-width: $breakpoint-lg) {
          width: 18px;
          height: 18px;
        }
      }

      .q-btn {
        &__content {
          gap: 5px;
          flex-wrap: nowrap;

          @media (max-width: $breakpoint-md) {
            gap: 5px;
            flex-wrap: wrap;
          }
        }
      }
    }

    &-button {
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;

      @media (max-width: $breakpoint-lg) {
        font-size: 13px;
      }
    }

    &-do-inventory-listings {
      width: 100%;
      white-space: nowrap;
      position: relative;
      max-width: 120px;

      @media (min-width: 1500px) {
        max-width: 135px;
      }

      @media (max-width: $breakpoint-lg) {
        white-space: normal;
        max-width: 110px;
      }

      @media (max-width: $breakpoint-sm) {
        max-width: 100%;
      }

      &:hover {
        cursor: pointer;
      }
      .q-select {
        border-bottom: none !important;
        background-color: #f7f7f7;
        border-radius: 4px;
        padding: 0 8px;

        @media (max-width: $breakpoint-lg) {
          padding: 0 6px;
        }

        span {
          color: var(--q-primary);
          font-weight: 500;

          @media (max-width: $breakpoint-lg) {
            font-size: 13px;
          }
        }

        label {
          color: var(--q-primary);
        }

        .q-field--standard .q-field__control {
          &::before {
            border-bottom: none !important;
          }
        }

        .q-field__append {
          padding-left: 0px;

          @media (max-width: $breakpoint-lg) {
            padding-left: 0;
          }

          .q-icon {
            color: #646464;
            background: #f7f7f7;
            width: 20px;
            height: 20px;
            border-radius: 50%;

            @media (max-width: $breakpoint-lg) {
              width: 18px;
              height: 18px;
            }
          }
        }

        .q-field__native {
          padding-right: 0;
          max-width: 85px;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (min-width: 1500px) {
            max-width: 100px;
          }

          @media (max-width: $breakpoint-lg) {
            max-width: 75px;
          }

          @media (max-width: $breakpoint-sm) {
            max-width: 100%;
          }
        }
      }

      @media (max-width: $breakpoint-sm) {
        width: 100%;
        margin: 10px 0;
      }
    }
  }

  // Mobile-specific layout improvements
  @media (max-width: $breakpoint-sm) {
    .mobile-info-grid {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .mobile-info-col {
        flex: 0 0 50%;
        margin-bottom: 8px;
      }
    }

    .inventory-items-card-wrapper {
      .q-item__section {
        padding: 0;
      }
    }
  }

  // Product line styling
  .product-line {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f0f0f0;
      cursor: pointer;
      border-radius: 8px;
      padding: 8px 12px;
      margin-top: 10px;
      min-height: 60px;

      @media (max-width: $breakpoint-lg) {
        padding: 8px;
        min-height: 56px;
      }

      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: flex-start;
        padding: 12px;
        gap: 8px;
      }
    }

    &-name {
      width: 30%;
      font-weight: 600;
      font-size: 16px;
      color: #333;

      @media (max-width: $breakpoint-lg) {
        font-size: 15px;
        width: 32%;
      }

      @media (max-width: $breakpoint-md) {
        font-size: 14px;
      }

      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }

    &-info {
      display: flex;
      gap: 20px;

      @media (max-width: $breakpoint-lg) {
        gap: 15px;
      }

      @media (max-width: $breakpoint-sm) {
        width: 100%;
        justify-content: space-between;
      }

      @media (max-width: $breakpoint-xs) {
        flex-direction: column;
        gap: 5px;
      }
    }
  }

  // Brand styling
  .brand-group {
    .brand-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f0f0f0;
      cursor: pointer;
      border-radius: 8px;
      padding: 6px 12px;
      margin-top: 10px;
      min-height: 60px;

      @media (max-width: $breakpoint-lg) {
        padding: 6px 8px;
        min-height: 56px;
      }

      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
        align-items: flex-start;
        padding: 12px;
        gap: 8px;
      }

      .brand-info {
        display: flex;
        align-items: center;
        margin-left: 6px;

        @media (max-width: $breakpoint-sm) {
          margin-left: 0;
          width: 100%;
        }

        .brand-logo {
          width: 40px;
          height: 40px;
          margin-right: 14px;
          border-radius: 4px;

          @media (max-width: $breakpoint-lg) {
            width: 36px;
            height: 36px;
            margin-right: 10px;
          }
        }

        .brand-name {
          font-size: 16px;
          font-weight: 600;

          @media (max-width: $breakpoint-lg) {
            font-size: 15px;
          }
        }
      }

      .brand-metrics {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-right: 16px;

        @media (max-width: $breakpoint-lg) {
          gap: 16px;
          margin-right: 12px;
        }

        @media (max-width: $breakpoint-sm) {
          width: 100%;
          margin-right: 0;
          justify-content: space-between;
        }

        @media (max-width: $breakpoint-xs) {
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
        }

        span {
          font-weight: 500;
          color: #2a6b0e;

          @media (max-width: $breakpoint-lg) {
            font-size: 14px;
          }
        }
      }
    }
  }

  // Product line section styling
  .product-line-section {
    margin-top: 16px;
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    @media (max-width: $breakpoint-lg) {
      margin-top: 14px;
      padding: 12px;
    }

    @media (max-width: $breakpoint-sm) {
      padding: 10px;
    }

    .inventory-items-header {
      margin-bottom: 10px;
      padding: 8px 0;
      border-bottom: 1px solid #f0f0f0;

      @media (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    &.product-drop-hover {
      border: 2px dashed var(--q-primary);
      background: rgba(42, 107, 14, 0.05);
      box-shadow: 0 0 15px rgba(42, 107, 14, 0.2);
    }
  }

  // Mobile-specific improvements
  @media (max-width: $breakpoint-sm) {
    &-card {
      &-wrapper {
        .mobile-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 100%;
          gap: 10px;
          margin: 10px 0;
        }
      }
    }
  }
}

// Transitions
.q-slide-transition {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

// Dragging styles
.drag-enter {
  outline-style: dashed;
}

.inventory-items-card-status-select {
  width: 150px;
}

// Utility classes for better responsiveness
.hide-on-mobile {
  @media (max-width: $breakpoint-sm) {
    display: none !important;
  }
}

.show-on-mobile {
  display: none !important;
  @media (max-width: $breakpoint-sm) {
    display: block !important;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-md) {
  .inventory-items-header {
    display: grid !important;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr auto;
    /* 
      - 2fr for the first column (Product Name).
      - 1fr for the next four columns (License, Category, Strain, QOH).
      - 'auto' for column 6, so Action Buttons remain top-right without overflow.
    */
    grid-template-rows: auto auto;
    gap: 8px;
    align-items: center;

    /* Kill old flex-based widths */
    > div {
      width: auto !important;
      flex: none !important;
    }

    /* Row 1 columns */
    .inventory-items-card-main {
      /* Product Name */
      grid-column: 1 / 2;
      grid-row: 1;
      padding-left: 60px;
    }
    .inventory-items-card-license {
      /* License */
      grid-column: 2 / 3;
      grid-row: 1;
    }
    .inventory-items-card-category-type {
      /* Category */
      grid-column: 3 / 4;
      grid-row: 1;
    }
    .inventory-items-card-strain-type {
      /* Strain */
      grid-column: 4 / 5;
      grid-row: 1;
    }
    .inventory-items-card-packages {
      /* QOH */
      grid-column: 5 / 6;
      grid-row: 1;
    }

    /* Row 2 columns */
    .inventory-items-card-location {
      grid-column: 1 / 2;
      grid-row: 2;
    }
    .inventory-items-card-listings {
      grid-column: 2 / 3;
      grid-row: 2;
    }
    .inventory-items-card-toggle {
      /* Status */
      grid-column: 3 / 4;
      grid-row: 2;
    }
  }

  .inventory-items-card {
    display: grid !important;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr auto;
    grid-template-rows: auto auto;
    gap: 8px;
    align-items: center;
    position: relative;

    > div {
      width: auto !important;
      flex: none !important;
      position: static !important;
    }

    /* --- Row 1 columns (same order as header) --- */
    .inventory-items-card-main {
      /* Product Name/Image */
      grid-column: 1 / 2;
      grid-row: 1;
      padding-left: 0 !important;
    }
    .inventory-items-card-license {
      grid-column: 2 / 3;
      grid-row: 1;
    }
    .inventory-items-card-category-type {
      grid-column: 3 / 4;
      grid-row: 1;
    }
    .inventory-items-card-strain-type {
      grid-column: 4 / 5;
      grid-row: 1;
    }
    .inventory-items-card-packages {
      /* QOH */
      grid-column: 5 / 6;
      grid-row: 1;
    }

    /* 3) Action Buttons -> top row, last column. Far right. */
    .inventory-items-card-actions-buttons {
      grid-column: 6 / 7;
      grid-row: 1;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      margin: 0;
    }

    /* --- Row 2 columns --- */
    .inventory-items-card-location {
      grid-column: 1 / 2;
      grid-row: 2;
    }
    .inventory-items-card-listings {
      grid-column: 2 / 3;
      grid-row: 2;
    }
    .inventory-items-card-toggle {
      /* Status dropdown */
      grid-column: 3 / 4;
      grid-row: 2;
    }
  }
}

.inventory-items-header {
  align-items: baseline !important; /* Force all items to align along their baseline */

  .sortable-header,
  .inventory-items-card-toggle {
    height: auto !important;
    display: flex !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  @media (max-width: $breakpoint-md) {
    .inventory-items-card-location,
    .inventory-items-card-listings,
    .inventory-items-card-toggle {
      display: none !important;
    }

    .inventory-items-card-main {
      flex: 0 0 30% !important;
    }

    .inventory-items-card-category-type,
    .inventory-items-card-strain-type {
      flex: 0 0 15% !important;
    }

    .inventory-items-card-packages {
      flex: 0 0 20% !important;
    }
  }

  @media (max-width: $breakpoint-sm) {
    .inventory-items-card-location,
    .inventory-items-card-listings,
    .inventory-items-card-toggle,
    .inventory-items-card-license,
    .inventory-items-card-category-type,
    .inventory-items-card-strain-type,
    .inventory-items-card-packages {
      display: none !important;
    }
  }
}
