.orders-page {
  background: $background;

  .orders {
    &-filters {
      margin: 16px 0;
    }

    &-header {
      display: flex;
      align-items: center;
      width: 100%;
      color: #646464;
      font-weight: 500;
      position: relative;
      justify-content: space-between;
      padding: 15px 5px;

      &-wrapper {
        padding: 15px 5px;
      }

      &-left {
        padding-left: 0.285em;
      }

      .sortable-header {
        min-height: unset;
        height: 20px;
        padding: 0;

        .q-btn__content {
          display: flex;
          align-items: center;
          min-width: 0;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .q-icon {
            flex-shrink: 0;
            font-size: 16px;
          }
        }
      }

      @media (max-width: 821px) {
        display: none;
      }

      @media (min-width: 1301px) {
        .orders-card-left {
          width: 4%;
        }
        .orders-card-source {
          width: 8%;
        }
        .orders-card-type {
          width: 8%;
        }
        .orders-card-number {
          width: 9%;
        }
        .orders-card-customer {
          width: 15%;
        }
        .orders-card-date {
          width: 12%;
        }
        .orders-card-shipped-date {
          width: 13%;
        }
        .orders-card-total {
          width: 10%;
        }
        .orders-card-status {
          width: 11%;
        }
        .orders-card-actions {
          width: 10%;
        }

        // Offers
        .orders-card-offers-title {
          width: 20%;
        }
        .orders-card-offers-type {
          width: 12%;
        }
        .orders-card-offers-date {
          width: 15%;
        }
        .orders-card-offers-qty {
          width: 12%;
        }
        .orders-card-offers-total {
          width: 15%;
        }
        .orders-card-offers-status {
          width: 16%;
        }
        .orders-card-offers-actions {
          width: 10%;
        }
      }

      @media (min-width: 1091px) and (max-width: 1300px) {
        .orders-card-left {
          width: 4%;
        }
        .orders-card-source {
          width: 7%;
        }
        .orders-card-type {
          width: 7%;
        }
        .orders-card-number {
          width: 10%;
        }
        .orders-card-customer {
          width: 11%;
        }
        .orders-card-date {
          width: 13%;
        }
        .orders-card-shipped-date {
          width: 13%;
        }
        .orders-card-total {
          width: 12%;
        }
        .orders-card-status {
          width: 15%;
        }
        .orders-card-actions {
          width: 10%;
        }

        // Offers
        .orders-card-offers-title {
          width: 20%;
        }
        .orders-card-offers-type {
          width: 12%;
        }
        .orders-card-offers-date {
          width: 15%;
        }
        .orders-card-offers-qty {
          width: 12%;
        }
        .orders-card-offers-total {
          width: 15%;
        }
        .orders-card-offers-status {
          width: 16%;
        }
        .orders-card-offers-actions {
          width: 10%;
        }
      }

      // Medium Screens - Fixed Alignment
      @media (min-width: 822px) and (max-width: 1090px) {
        .orders-card-left {
          width: 5%;
        }
        .orders-card-source {
          width: 10%;
        }
        .orders-card-type {
          display: none;
        }
        .orders-card-number {
          width: 10%;
        }
        .orders-card-customer {
          padding-left: 1.4rem;
          width: 17%;
        }
        .orders-card-date {
          width: 12%;
        }
        .orders-card-shipped-date {
          width: 16%;
        }
        .orders-card-total {
          width: 10%;
        }
        .orders-card-status {
          width: 10%;
        }
        .orders-card-actions {
          width: 10%;
        }

        // Offers
        .orders-card-offers-title {
          width: 20%;
        }
        .orders-card-offers-type {
          width: 12%;
        }
        .orders-card-offers-date {
          width: 15%;
        }
        .orders-card-offers-qty {
          width: 12%;
        }
        .orders-card-offers-total {
          width: 15%;
        }
        .orders-card-offers-status {
          width: 16%;
        }
        .orders-card-offers-actions {
          width: 10%;
        }
      }
    }

    &-scroll {
      max-height: 100vh;

      &-item {
        padding: 0;
      }
    }

    &-card {
      color: $dark;
      font-size: 14px;
      font-weight: 500;
      width: 100%;

      @media (min-width: 821px) {
        display: flex;
        align-items: center;
      }

      &-wrapper {
        cursor: pointer;
        border-radius: 8px;
        padding: 10px 5px;
        margin-bottom: 10px;
        min-height: 60px;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &:not(.q-skeleton) {
          transition: box-shadow 0.3s ease-in-out;

          &:hover {
            box-shadow: 0 0 1.25rem #0000001a;
          }
        }
      }

      &-left {
        width: 3%;

        @media (min-width: 1301px) {
          width: 5%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 5%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 5%;
        }
      }

      &-source {
        width: 5%;

        @media (min-width: 1301px) {
          width: 8%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 7%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 10%;
        }
      }

      &-type {
        width: 5%;

        @media (min-width: 1301px) {
          width: 8%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 7%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          display: none;
        }
      }

      &-number {
        width: 8%;

        @media (min-width: 1301px) {
          width: 9%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 8%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 8%;
          margin-left: 9px;
        }
      }

      &-customer {
        width: 15%;

        @media (min-width: 1301px) {
          width: 15%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 13%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 15%;
        }
      }

      &-date {
        width: 15%;

        @media (min-width: 1301px) {
          width: 12%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 10%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 12%;
        }
      }

      &-shipped-date {
        width: 15%;

        .q-mb-xs {
          margin-bottom: 4px !important;
        }

        .full-width-btn {
          padding: 2px 8px !important;
          font-size: 12px;
          white-space: nowrap;
          width: calc(100% - 4px) !important;
          margin: 2px;

          .q-btn__content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block !important;
          }
        }

        @media (min-width: 1301px) {
          width: 13%;
          .full-width-btn {
            font-size: 13px;
          }
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 13%;
          min-width: 140px;

          .full-width-btn {
            padding: 1px 6px !important;
          }
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 16%;
        }
        @media (max-width: 823px) {
          width: 16%;

          .q-btn {
            width: 100% !important;
          }
        }
      }

      &-total {
        width: 10%;

        @media (min-width: 1301px) {
          width: 10%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 8%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 14%;

          .full-width-btn {
            padding: 1px 4px !important;
          }
        }

        @media (max-width: 821px) {
          .full-width-btn {
            width: auto !important;
            margin-left: 100px;
          }
        }
      }

      &-status {
        width: 10%;

        @media (min-width: 1301px) {
          width: 10%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 12%;
          min-width: 110px;
          padding-right: 4px;
          justify-content: flex-end;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 12%;
          display: flex;
          align-items: center;

          .q-badge {
            width: 100%;
            height: auto;
            white-space: normal;
            text-align: center;
          }
        }
      }

      &-actions {
        width: 15%;
        display: flex;
        gap: 6px;
        justify-content: flex-end;
        margin-left: 0;

        @media (min-width: 1301px) {
          .q-btn {
            .q-icon {
              font-size: 18px;
            }
          }
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 12%;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 2px;

          .q-btn {
            padding: 2px;

            .q-icon {
              font-size: 18px;
            }
          }
        }
        @media (min-width: 1120px) and (max-width: 1091px) {
          width: 12%;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 2px;

          .q-btn {
            padding: 2px;

            .q-icon {
              font-size: 18px;
            }
          }
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 10%;
          flex-wrap: wrap;
          gap: 2px;

          .q-btn {
            padding: 4px !important;
            min-height: 24px;

            .q-icon {
              font-size: 16px;
            }
          }
        }

        @media (max-width: 821px) {
          padding-top: 8px;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-left: 6.4rem;
        }
      }

      &-offers {
        &-title {
          width: 20%;
        }
        &-type {
          width: 15%;
        }
        &-date {
          width: 15%;
        }
        &-qty {
          width: 10%;
        }
        &-total {
          width: 15%;
        }
        &-status {
          width: 15%;
        }
        &-actions {
          width: 10%;
          display: flex;
          gap: 10px;
          justify-content: flex-end;
          margin-left: 0;

          @media (min-width: 1301px) {
            width: 10%;
          }
          @media (min-width: 1091px) and (max-width: 1300px) {
            width: 10%;
          }
          @media (min-width: 822px) and (max-width: 1090px) {
            width: 10%;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
          }
        }
      }

      &-checkbox {
        margin-right: 5px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0;

        .q-checkbox__inner--falsy {
          .q-checkbox__bg {
            background: #fff;
          }
        }

        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 40px;
          padding-top: 0;
          display: flex;
          align-items: center;
        }
      }

      > div {
        padding-left: 0.285em;
      }

      @media (max-width: 1300px) {
        .q-badge {
          max-width: 100%;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
        }
      }

      @media (max-width: 821px) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;

        [class*="orders-card-"] {
          width: 100%;
          padding: 4px 0;
          display: flex;
          align-items: center;

          &.orders-card-type {
            display: flex;
          }

          &:not(.orders-card-left):not(.orders-card-actions)::before {
            content: attr(data-label);
            font-weight: 600;
            min-width: 100px;
            margin-right: 8px;
          }
        }

        [class*="orders-card-offers-"] {
          width: 100%;
          padding: 4px 0;
          display: flex;
          align-items: center;

          &:not(.orders-card-offers-actions)::before {
            content: attr(data-label);
            font-weight: 600;
            min-width: 100px;
            margin-right: 8px;
          }
        }

        &-offers-actions,
        &-actions {
          padding-top: 8px;
          // border-top: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-left: 6.4rem;
        }
      }

      [class*="orders-card-"] {
        @media (min-width: 822px) {
          display: flex;
          align-items: center;
        }
      }

      .orders-card-actions {
        @media (min-width: 822px) {
          flex-wrap: wrap;
        }
      }

      .full-width-btn {
        width: 100%;
        max-width: 100%;
        margin-bottom: 5px;

        @media (min-width: 822px) {
          width: auto;
          margin-bottom: 0;
        }
      }
    }

    &-pagination {
      gap: 5px;

      .row {
        gap: 5px;
      }

      .q-btn {
        background: #fff;
        padding: 5px !important;
      }
    }
  }

  .single-order {
    &-card {
      border-radius: 8px;
      padding: 10px 12px;
      background: #fff;
      height: 100%;

      &-label {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

.order-badge {
  padding: 10px 15px;
}

.order-shipping-row {
  &.move-top {
    margin-top: -20px;
  }

  .q-btn-dropdown {
    .q-btn__content {
      justify-content: space-between;
    }
  }
}

.order-items-table {
  &-row {
    background: #fff;
    border-radius: 8px;
    padding: 6px 12px;
    margin-bottom: 10px;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    &.accepted {
      background: #ddecdf;
    }
    &.declined {
      background: #ffcdd2;
    }

    &-left {
      width: 15%;

      @media (max-width: 900px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    &-product {
      width: 20%;

      &-image {
        margin-right: 8px;
      }

      &-name {
        font-weight: 600;
      }

      @media (max-width: 900px) {
        width: 100%;
        margin-bottom: 10px;

        .row {
          width: 100%;

          .q-img {
            width: 80px !important;
          }
        }
      }
    }

    &-brand,
    &-qty,
    &-qoh,
    &-unit-price,
    &-total-price {
      width: 9%;
      font-weight: 500;

      @media (max-width: 900px) {
        width: 100%;
        padding: 8px 0;
        position: relative;

        &::before {
          content: attr(data-label);
          font-weight: 600;
          min-width: 140px;
          display: inline-block;
          margin-right: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
      }
    }

    &-notes {
      width: 20%;

      &.small {
        width: 10%;
      }

      @media (max-width: 900px) {
        width: 100%;
        padding: 8px 0;
        position: relative;

        &::before {
          content: attr(data-label);
          font-weight: 600;
          min-width: 140px;
          display: inline-block;
          margin-right: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
      }
    }

    &-actions {
      width: 7%;
      text-align: right;

      @media (min-width: 901px) {
        width: 7%;
      }

      @media (max-width: 900px) {
        width: 100%;
        padding: 12px 0 4px;
        text-align: left;

        span {
          display: inline-block;
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 500;
          border-radius: 4px;

          &.text-primary {
            background-color: rgba(25, 118, 210, 0.12);
          }

          &.text-negative {
            background-color: rgba(211, 47, 47, 0.12);
          }
        }
      }
    }

    &-divider {
      width: 100%;
      height: 2px;
      background: $grey-3;
      margin-top: 10px;
    }

    &-packages {
      padding-top: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;

      @media (max-width: 900px) {
        padding: 16px 0;
      }

      .packages-main {
        width: 100%;
        max-width: 800px;

        table {
          width: 100%;
          border-collapse: collapse;

          th {
            text-align: left;
            padding: 8px 4px;
            font-weight: 600;
            font-size: 13px;
          }

          td {
            padding: 8px 4px;
            vertical-align: middle;

            @media (max-width: 768px) {
              &::before {
                content: attr(data-label);
              }
            }
          }

          @media (max-width: 768px) {
            display: block;

            thead {
              display: none;
            }

            tbody {
              display: block;

              tr {
                display: block;
                margin-bottom: 16px;
                padding: 12px;
                background: rgba(0, 0, 0, 0.03);
                border-radius: 4px;

                td {
                  display: flex;
                  width: 100%;
                  padding: 6px 0;
                  align-items: center;

                  &::before {
                    font-weight: 600;
                    min-width: 140px;
                    margin-right: 10px;
                  }

                  &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                  }

                  &:nth-child(3) {
                    justify-content: center;

                    .q-btn {
                      width: 100%;
                      max-width: 300px;
                      margin: 8px 0;
                    }
                  }
                }
              }
            }
          }
        }

        .single-order-card-label {
          margin: 16px 0 8px;
          font-size: 14px;
        }

        @media (max-width: 900px) {
          max-width: 100%;
        }

        // Fix for package expansion sections
        @media (max-width: 900px) {
          .q-mt-lg {
            > div {
              margin: 12px 0;
              padding: 8px 0;

              &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
              }

              &.text-negative {
                background-color: rgba(211, 47, 47, 0.08);
                padding: 10px;
                border-radius: 4px;
              }

              .single-order-card-label {
                display: block;
                margin-bottom: 6px;
              }
            }
          }
        }
      }

      .new-package {
        width: 50%;

        &-form {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;
          grid-auto-rows: minmax(100px, auto);

          @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
        }

        @media (max-width: 900px) {
          width: 100%;
        }
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      padding: 16px;
    }
  }

  &-header {
    padding: 5px 12px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    position: relative;

    &.left-padding {
      padding-left: 57px;
    }

    .bulk-actions {
      position: absolute;
      left: 0;

      .q-checkbox__label {
        margin-left: 20px;
      }
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  &-accepted {
    background: #fff;
    border-radius: 8px;
  }

  &-checkbox {
    width: 45px;
    // padding-top: 20px;
    align-self: center;
  }
}

@media (max-width: 821px) {
  .orders-page {
    .orders-card {
      [class*="orders-card-"]:not(.orders-card-left):not(.orders-card-actions)::before {
        font-weight: 600;
        min-width: 100px;
        margin-right: 10px;
      }

      &-shipped-date {
        .full-width-btn {
          min-height: 36px;
          padding: 4px 10px !important;
          font-size: 13px;
          margin: 3px 0;
        }
      }

      &-actions {
        padding-top: 10px;
        gap: 10px;

        .q-btn {
          min-width: 36px;
          min-height: 36px;

          .q-icon {
            font-size: 18px;
          }
        }
      }
    }
  }

  .orders-pagination {
    .q-btn {
      min-width: 38px;
      min-height: 38px;
      font-size: 14px;
    }
  }
}

.q-badge {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 4px 8px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  line-height: 1.2;
}

.q-badge:not(.q-badge--outline) {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.q-badge--outline {
  background: transparent !important;
  border-width: 2px;
  border-style: solid;
  box-shadow: none;
  padding: 5px 11px;
}

.q-badge.bg-negative {
  background-color: #d32f2f !important;
  border-color: #d32f2f;
}

.q-badge.text-positive {
  color: #2e7d32 !important;
  border-color: #2e7d32;
}

.q-badge.bg-positive {
  background-color: #2e7d32 !important;
  border-color: #2e7d32;
}

.q-badge.text-warning {
  color: #f57c00 !important;
  border-color: #f57c00;
}

.q-badge.bg-warning {
  background-color: #f57c00 !important;
  border-color: #f57c00;
  color: white !important;
}

.q-badge.text-info {
  color: #1976d2 !important;
  border-color: #1976d2;
}

.q-badge.bg-info {
  background-color: #1976d2 !important;
  border-color: #1976d2;
}

.q-badge.text-primary {
  color: #1976d2 !important;
  border-color: #1976d2;
}

.q-badge.bg-primary {
  background-color: #1976d2 !important;
  border-color: #1976d2;
}

.order-header .q-badge,
.single-order-card .q-badge {
  font-size: 13px;
  padding: 7px 14px;
}

.q-btn {
  &.q-btn--dense {
    padding: 2px 4px;
  }
}

.my-orders-header {
  &-select {
    min-width: 100px;
  }

  &-dropdown {
    min-width: 150px;
    font-size: 14px;
    font-weight: normal;
    text-transform: capitalize;

    &.per-page {
      min-width: 100px;
    }
  }
}

@media (max-width: 821px) {
  .orders-page {
    .orders-card {
      &-offers-actions,
      &-actions {
        width: 100%;
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-left: 0 !important;
        padding-left: 10px;
        justify-content: flex-start;

        .q-btn {
          min-width: 44px;
          min-height: 44px;

          .q-icon {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 821px) {
  .orders-page {
    .orders-card {
      [class*="orders-card-"] {
        padding: 2px 0 10px 0;

        &:not(.orders-card-left):not(.orders-card-actions)::before {
          font-weight: 600;
          min-width: 130px;
          margin-right: 12px;
        }
      }

      [class*="orders-card-offers-"] {
        padding: 8px 0;

        &:not(.orders-card-offers-actions)::before {
          font-weight: 600;
          min-width: 130px;
          margin-right: 12px;
        }
      }

      gap: 12px;

      > div:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }

      &-status .q-badge {
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 500;
      }

      &-shipped-date {
        .full-width-btn {
          min-height: 38px;
          padding: 6px 12px !important;
          font-size: 14px;
          margin: 4px 0;
        }
      }

      &-wrapper {
        padding: 12px 10px;
      }
    }
  }

  .order-items-table-row {
    padding: 14px;

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
}

@media (min-width: 1200px) {
  .order-items-table-row {
    padding: 14px 18px;

    &-left {
      width: 15%;
    }

    &-product {
      width: 22%;

      .row {
        align-items: center;
      }
    }

    &-actions {
      span {
        display: inline-block;
        padding: 2px 6px;
        border-radius: 4px;
      }
    }
  }
}

.order-items-table-header .sortable-header {
  height: 24px !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;

  .q-btn__content {
    height: auto !important;
    line-height: 1 !important;
    min-height: 0 !important;
    padding: 0 !important;
  }
}

.order-items-table-header .q-btn.sortable-header span {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle;
}

.order-items-table-header .q-btn.sortable-header .q-icon {
  line-height: 1.2;
  height: auto;
  vertical-align: middle;
}
