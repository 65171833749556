// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: #2a6b0e;
$secondary: #edf5ee;
$accent: #5faa43;

$dark: #0f3427;
$lightAccent: #f2b518;
$positive: #21ba45;
$negative: #c10004;
$info: #0a559d;
$warning: #f1db10;

$background: #f8f9f8;

$auction: #288ddf;

$breakpoint-xs: 599px;
$breakpoint-sm: 1023px;
$breakpoint-md: 1439px;
$breakpoint-lg: 1919px;
