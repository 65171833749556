.auction-carousel {
  &-contents {
    height: 40vh;
    min-height: 350px;
    width: 100%;
    font-size: 2vh;
  }
  &-card {
    height: 37vh;
    min-height: 340px;
    width: 100%;
    min-width: 206px;
    max-width: 23vh;
    border-radius: 0.75vh; // card corners
    overflow: hidden;

    h5 {
      font-size: 1.6em;
    }

    h6 {
      font-size: 1.3em;
    }

    .q-icon {
      font-size: 2.2em;
    }

    &-price,
    &-title {
      padding-left: 2vh;
      padding-right: 2vh;
      font-size: small;
    }

    &-action {
      padding-left: 1vh;
      padding-right: 1vh;
      padding-top: 0.2vh;
    }

    &-image {
      height: 55%;
    }

    .q-img {
      &.fallback-image {
        .q-img__image {
          object-fit: contain !important;
        }
      }

      &:after {
        position: absolute;
        display: block;
        top: 0; // fix to top of card
        width: 100%; // span width of card
        text-align: center;
        letter-spacing: 0.1em;
        padding: 1em;
        color: #ffffff;
        text-shadow: 1px 1px 8px rgb(0 0 0 / 70%);
        box-shadow: 0px -2px 18px rgb(0 0 0 / 80%);
        font-weight: bold;
        font-size: 10pt;
        line-height: 0.5em;
        text-decoration: none;
        z-index: 999;
      }

      &__content > div {
        background: linear-gradient(
          rgba(0, 0, 0, 0.32),
          rgba(255, 255, 255, 0.27),
          rgba(0, 0, 0, 0.67)
        );
        transition: background-image 5s;
      }
    }

    &:hover {
      .q-img__content > div {
        background: linear-gradient(
          rgba(0, 0, 0, 0.47),
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.97)
        );
        box-shadow: inset 3px -2px 25px adjust-color($dark, $alpha: -0.6);

        .auction-carousel-card-price {
          opacity: 0.9;
          text-shadow: -1px 3px 12px black;
        }
      }
    }

    &[yours="true"] .q-img:after {
      content: "Yours";
      background: $info;
    }

    &[auction="true"] .q-img:after {
      content: "Auction";
      background: $auction;
    }

    &[winning="true"] .q-img:after {
      content: "Winning";
      background: $primary;
    }

    &[losing="true"] .q-img:after {
      content: "Outbid";
      background: $negative;
    }

    &[accepted="true"] .q-img:after {
      content: "Accepted";
      background: $primary;
    }

    &[rejected="true"] .q-img:after {
      content: "Rejected";
      background: $negative;
    }

    &[declined="true"] .q-img:after {
      content: "Declined";
      background: $negative;
    }

    &[responded="true"] .q-img:after {
      content: "Responded";
      background: $accent;
    }

    &[paid="true"] .q-img:after {
      content: "Paid";
      background: $dark;
    }

    &[paid="false"] .q-img:after {
      content: "Unpaid";
      background: $negative;
    }
  }
}

.mobile-view-all {
  @media (max-width: 450px) {
    margin-top: 15px;
  }
}

.mobile-line-break {
  @media (max-width: 450px) {
    max-width: 280px;
  }
}

.auction-categories {
  .auction-categories-content {
    .q-chip--outline {
      opacity: 0.8;
      background-color: $secondary !important;
    }
  }
}

.auction-listing-modal {
  .listing-page-about-table {
    width: 100%;
    margin: 24px 0 20px;
  }
}
